import { Route, Routes } from 'react-router-dom';
import './App.css';
import About from './pages/About';
import Contact from './pages/Contact';
import Fauxwood from './pages/Fauxwood';
import Roller from './pages/Roller';
import Home from './pages/Home';
import SingleProduct from './pages/SingleProduct';
import Zebra from './pages/Zebra';

function App() {
  return (
    <div class="main-wrapper">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/About" element={<About />} />
        <Route path="/Shop/Zebra" element={<Zebra />} />
        <Route path="/Shop/Fauxwood" element={<Fauxwood />} />
        <Route path="/Shop/Roller" element={<Roller />} />
        <Route path="/Product" element={<SingleProduct />} />
      </Routes>
    </div >
  )
}

export default App;
