import React from 'react'
import Slider from "react-slick"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SingleProductDetails = () => {
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    var settings1 = {
        slidesToShow: 3,
        asNavFor: ".sp-img_slider",
        focusOnSelect: true,
        arrows: true,
        spaceBetween: 30,
        responsive: [
            { breakpoint: 1501, settings: { slidesToShow: 3 } },
            { breakpoint: 1200, settings: { slidesToShow: 2 } },
            { breakpoint: 992, settings: { slidesToShow: 4 } },
            { breakpoint: 768, settings: { slidesToShow: 3 } },
            { breakpoint: 575, settings: { slidesToShow: 2 } }
        ]
    };

    return (
        <>
            <div class="sp-area sp-affiliate_area pt-30">
                <div class="container">
                    <div class="sp-nav">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="sp-img_area">
                                    <div class="sp-img_slider slick-img-slider quicky-element-carousel">
                                        <Slider {...settings}>
                                            <div class="single-slide red zoom">
                                                <img src="/assets/images/furniture/furniture1.jpg" alt="DBS Supplies Furniture" />
                                            </div>
                                            <div class="single-slide orange zoom">
                                                <img src="/assets/images/furniture/furniture2.jpg" alt="DBS Supplies Furniture" />
                                            </div>
                                        </Slider>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="sp-content ml-lg-4">
                                    <div class="sp-heading">
                                        <h5><a href="#">Moonstar Clock</a></h5>
                                    </div>
                                    <span class="reference">Reference: demo_1</span>
                                    <div class="rating-box">
                                        <ul>
                                            <li><i class="zmdi zmdi-star"></i></li>
                                            <li><i class="zmdi zmdi-star"></i></li>
                                            <li><i class="zmdi zmdi-star"></i></li>
                                            <li class="silver-color"><i class="zmdi zmdi-star"></i></li>
                                            <li class="silver-color"><i class="zmdi zmdi-star"></i></li>
                                        </ul>
                                    </div>
                                    <div class="sp-essential_stuff">
                                        <ul>
                                            <li>Brands <a href="javascript:void(0)">Buxton</a></li>
                                            <li>Product Code: <a href="javascript:void(0)">Product 16</a></li>
                                            <li>Reward Points: <a href="javascript:void(0)">100</a></li>
                                            <li>Availability: <a href="javascript:void(0)">In Stock</a></li>
                                            <li>EX Tax: <a href="javascript:void(0)"><span>$453.35</span></a></li>
                                            <li>Price in reward points: <a href="javascript:void(0)">400</a></li>
                                        </ul>
                                    </div>
                                    <div class="qty-btn_area">
                                        <a href="javascrip:void(0)" class="qty-buy_btn">Buy Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="product-tab_area pt-90">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="sp-product-tab_nav">
                                <div class="product-tab">
                                    <ul class="nav product-menu">
                                        <li><a class="active" data-bs-toggle="tab" href="#description"><span>Description</span></a>
                                        </li>
                                        <li><a data-bs-toggle="tab" href="#specification"><span>Specification</span></a></li>
                                    </ul>
                                </div>
                                <div class="tab-content uren-tab_content">
                                    <div id="description" class="tab-pane active show" role="tabpanel">
                                        <div class="product-description">
                                            <ul>
                                                <li>
                                                    <span class="title">Ullam aliquam</span>
                                                    <span>Voluptatum, minus? Optio molestias voluptates aspernatur laborum
                                                        ratione minima, natus eaque nemo rem quisquam, suscipit architecto
                                                        saepe. Debitis omnis labore laborum consectetur, quas, esse voluptates
                                                        minus aliquam modi nesciunt earum! Vero rerum molestiae corporis libero
                                                        repellat doloremque quae sapiente ratione maiores qui aliquam, sunt
                                                        obcaecati! Iure nisi doloremque numquam delectus.</span>
                                                </li>
                                                <li>
                                                    <span class="title">Enim tempore</span>
                                                    <span>Molestias amet quibusdam eligendi exercitationem alias labore tenetur
                                                        quaerat veniam similique aspernatur eveniet, suscipit corrupti itaque
                                                        dolore deleniti nobis, rerum reprehenderit recusandae. Eligendi beatae
                                                        asperiores nisi distinctio doloribus voluptatibus voluptas repellendus
                                                        tempore unde velit temporibus atque maiores aliquid deserunt aspernatur
                                                        amet, soluta fugit magni saepe fugiat vel sunt voluptate vitae</span>
                                                </li>
                                                <li>
                                                    <span class="title">Laudantium suscipit</span>
                                                    <span>Odit repudiandae maxime, ducimus necessitatibus error fugiat nihil eum
                                                        dolorem animi voluptates sunt, rem quod reprehenderit expedita, nostrum
                                                        sit accusantium ut delectus. Voluptates at ipsam, eligendi labore
                                                        dignissimos consectetur reprehenderit id error excepturi illo velit
                                                        ratione nisi nam saepe quod! Reiciendis eos, velit fugiat voluptates
                                                        accusamus nesciunt dicta ratione mollitia, asperiores error aliquam!
                                                        Reprehenderit provident, omnis blanditiis fugit, accusamus deserunt
                                                        illum unde, voluptatum consequuntur illo officiis labore doloremque
                                                        quidem aperiam! Fuga, expedita? Laboriosam eum, tempore vitae libero
                                                        voluptate omnis ducimus doloremque hic quibusdam reiciendis ab itaque
                                                        aperiam maiores laudantium esse, consequuntur quos labore modi quasi
                                                        recusandae distinctio iusto optio officia tempora.</span>
                                                </li>
                                                <li>
                                                    <span class="title">Molestiae veritatis officia</span>
                                                    <span>Illum fuga esse tenetur inventore, in voluptatibus saepe iste quia
                                                        cupiditate, explicabo blanditiis accusantium ut. Eaque nostrum, quisquam
                                                        doloribus asperiores tempore autem. Ea perspiciatis vitae reiciendis
                                                        maxime similique vel, id ratione blanditiis ullam officiis odio sunt nam
                                                        quos atque accusantium ad! Repellendus, magni aliquid. Iure asperiores
                                                        veniam eum unde dignissimos reprehenderit ut atque velit, harum labore
                                                        nam expedita, pariatur excepturi consectetur animi optio mollitia ad a
                                                        natus eaque aut assumenda inventore dolor obcaecati! Enim ab tempore
                                                        nulla iusto consequuntur quod sit voluptatibus adipisci earum fuga,
                                                        explicabo amet, provident, molestiae optio. Ducimus ex necessitatibus
                                                        assumenda, nisi excepturi ut aspernatur est eius dignissimos pariatur
                                                        unde ipsum sunt quaerat.</span>
                                                </li>

                                            </ul>
                                        </div>
                                    </div>
                                    <div id="specification" class="tab-pane" role="tabpanel">
                                        <table class="table table-bordered specification-inner_stuff">
                                            <tbody>
                                                <tr>
                                                    <td colspan="2"><strong>Memory</strong></td>
                                                </tr>
                                            </tbody>
                                            <tbody>
                                                <tr>
                                                    <td>test 1</td>
                                                    <td>8gb</td>
                                                </tr>
                                            </tbody>
                                            <tbody>
                                                <tr>
                                                    <td colspan="2"><strong>Processor</strong></td>
                                                </tr>
                                            </tbody>
                                            <tbody>
                                                <tr>
                                                    <td>No. of Cores</td>
                                                    <td>1</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SingleProductDetails