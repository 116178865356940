import React from 'react'
import Slider from "react-slick"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from '../components/Footer';

const Zebra = () => {
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    var settings1 = {
        slidesToShow: 3,
        asNavFor: ".sp-img_slider",
        focusOnSelect: true,
        arrows: true,
        spaceBetween: 30,
        responsive: [
            { breakpoint: 1501, settings: { slidesToShow: 3 } },
            { breakpoint: 1200, settings: { slidesToShow: 2 } },
            { breakpoint: 992, settings: { slidesToShow: 4 } },
            { breakpoint: 768, settings: { slidesToShow: 3 } },
            { breakpoint: 575, settings: { slidesToShow: 2 } }
        ]
    };

    return (
        <>
            <div class="sp-area sp-affiliate_area pt-30">
                <div class="container">
                    <div class="sp-nav">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="sp-img_area">
                                    <div class="sp-img_slider slick-img-slider quicky-element-carousel">
                                        <Slider {...settings}>
                                            <div class="single-slide red zoom">
                                                <img src="https://dbsblinds.ca/images/zebra01.jpg" alt="DBS Supplies Furniture" />
                                            </div>
                                            <div class="single-slide orange zoom">
                                                <img src="https://dbsblinds.ca/images/zebra04.jpg" alt="DBS Supplies Furniture" />
                                            </div>
                                        </Slider>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="sp-content ml-lg-4">
                                    <div class="sp-heading">
                                        <h5><a href="#"> Zebra Blinds</a></h5>
                                    </div>
                                    <p>
                                        Zebra Blinds, also referred to as High-Light Blinds or Transition Blinds, are classy window covering choice for any home. Similar in structure to the roller shades, these blinds have a singular style with two fabric layers alternating to create complete light filtering or dim out, sheer view, or a combination of the two – creating a similar effect to a zebra pattern.
                                    </p>
                                    <div class="rating-box">
                                        <ul>
                                            <li><i class="zmdi zmdi-star"></i></li>
                                            <li><i class="zmdi zmdi-star"></i></li>
                                            <li><i class="zmdi zmdi-star"></i></li>
                                            <li class="silver-color"><i class="zmdi zmdi-star"></i></li>
                                            <li class="silver-color"><i class="zmdi zmdi-star"></i></li>
                                        </ul>
                                    </div>
                                    <div class="sp-essential_stuff">
                                        <ul>
                                            <li>Brands <a href="javascript:void(0)">DBS Supllies</a></li>
                                            <li>Product Code: <a href="javascript:void(0)">dbs-supplies 1we6</a></li>
                                            <li>Availability: <a href="javascript:void(0)">In Stock</a></li>
                                        </ul>
                                    </div>
                                    <div class="qty-btn_area">
                                        <a href="javascrip:void(0)" class="qty-buy_btn">Buy Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="product-tab_area pt-90">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="sp-product-tab_nav">
                                <div class="product-tab">
                                    <ul class="nav product-menu">
                                        <li><a class="active" data-bs-toggle="tab" href="#description"><span>Description</span></a>
                                        </li>
                                        <li><a data-bs-toggle="tab" href="#specification"><span>Specification</span></a></li>
                                    </ul>
                                </div>
                                <div class="tab-content uren-tab_content">
                                    <div id="description" class="tab-pane active show" role="tabpanel">
                                        <div class="product-description">
                                            <ul>
                                                <li>
                                                    <span class="title">Description</span>
                                                    The unique design allows for the ultimate in control over, view, privacy, light, and style in the one blind. This makes them the perfect option for rooms such as living rooms, bedrooms and studies. With the light filtering fabric open, an ambient level of light can enter the room whilst maintaining privacy and when the sheer fabric is open, it is the perfect way to allow the natural sunlight to flood the room. Zebra Blinds are easy to clean and maintain with strong durable fabrics and components. They are simple to operate, with a colour coordinated cord attached for easy transitioning of the fabric to the desired position.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Zebra