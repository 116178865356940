import React from 'react';
import Banner from '../components/Banner';
import Footer from '../components/Footer';
import ServiceBanner from '../components/ServiceBanner';
import Slider from '../components/Slider';

const Home = () => {
    return (
        <>
            <Slider />
            <Banner />
            <ServiceBanner />
            <Footer />
        </>
    );
};

export default Home;
