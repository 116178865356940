import React from 'react'
import BreadcrumbSmall from '../components/BreadcrumbSmall'
import SingleProductDetails from '../components/SingleProductDetails'
import Footer from '../components/Footer'

const SingleProduct = () => {
    return (
        <>
            <BreadcrumbSmall />
            <SingleProductDetails />
            <Footer />
        </>
    )
}

export default SingleProduct