import React from 'react'
import Slider from "react-slick"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from '../components/Footer';

const Fauxwood = () => {
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    var settings1 = {
        slidesToShow: 3,
        asNavFor: ".sp-img_slider",
        focusOnSelect: true,
        arrows: true,
        spaceBetween: 30,
        responsive: [
            { breakpoint: 1501, settings: { slidesToShow: 3 } },
            { breakpoint: 1200, settings: { slidesToShow: 2 } },
            { breakpoint: 992, settings: { slidesToShow: 4 } },
            { breakpoint: 768, settings: { slidesToShow: 3 } },
            { breakpoint: 575, settings: { slidesToShow: 2 } }
        ]
    };

    return (
        <>
            <div class="sp-area sp-affiliate_area pt-30">
                <div class="container">
                    <div class="sp-nav">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="sp-img_area">
                                    <div class="sp-img_slider slick-img-slider quicky-element-carousel">
                                        <Slider {...settings}>
                                            <div class="single-slide red zoom">
                                                <img src="https://dbsblinds.ca/images/roller1.jpg" alt="DBS Supplies Furniture" />
                                            </div>
                                            <div class="single-slide orange zoom">
                                                <img src="https://dbsblinds.ca/images/roller2.jpg" alt="DBS Supplies Furniture" />
                                            </div>
                                        </Slider>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="sp-content ml-lg-4">
                                    <div class="sp-heading">
                                        <h5><a href="#">Roller Shades</a></h5>
                                    </div>
                                    <p>
                                        Roller Blinds offer a straightforward however trendy and effective window coverings answer. the sunshine weight construction of the window shade makes them good for Canadian Windows.
                                    </p>
                                    <div class="rating-box">
                                        <ul>
                                            <li><i class="zmdi zmdi-star"></i></li>
                                            <li><i class="zmdi zmdi-star"></i></li>
                                            <li><i class="zmdi zmdi-star"></i></li>
                                            <li class="silver-color"><i class="zmdi zmdi-star"></i></li>
                                            <li class="silver-color"><i class="zmdi zmdi-star"></i></li>
                                        </ul>
                                    </div>
                                    <div class="sp-essential_stuff">
                                        <ul>
                                            <li>Brands <a href="javascript:void(0)">DBS Supllies</a></li>
                                            <li>Product Code: <a href="javascript:void(0)">dbs-supplies 1we6</a></li>
                                            <li>Availability: <a href="javascript:void(0)">In Stock</a></li>
                                        </ul>
                                    </div>
                                    <div class="qty-btn_area">
                                        <a href="javascrip:void(0)" class="qty-buy_btn">Buy Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="product-tab_area pt-90">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="sp-product-tab_nav">
                                <div class="product-tab">
                                    <ul class="nav product-menu">
                                        <li><a class="active" data-bs-toggle="tab" href="#description"><span>Description</span></a>
                                        </li>
                                        <li><a data-bs-toggle="tab" href="#specification"><span>Specification</span></a></li>
                                    </ul>
                                </div>
                                <div class="tab-content uren-tab_content">
                                    <div id="description" class="tab-pane active show" role="tabpanel">
                                        <div class="product-description">
                                            <ul>
                                                <li>
                                                    <span class="title">Description</span>
                                                    <span>
                                                        The DBS range of fabrics offer varying degrees of transparency for your light and viewing preference. Choose a blockout fabric for complete privacy and protection from the hot sun (great for bedrooms) or maybe a Light Filter fabric for a living area to keep privacy during the day and allow an ambient level of light into the room.
                                                        Advantages include easy cleaning, light to operate, they reduce glare, improve the insulation of your home and add privacy during the day while keeping your furniture and carpets protected from UV rays.
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Fauxwood