import React from 'react';

const ServiceBanner = () => {
    return (
        <div class="service-area pt-100 pb-90">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="service-item">
                            <div class="service-img">
                                <img src="assets/images/service/1.png" alt="Quicky's Service" />
                            </div>
                            <div class="service-content">
                                <h3 class="heading">Free Home Delivery</h3>
                                <p class="short-desc">Provide free home delivery
                                    for all product over $100</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="service-item">
                            <div class="service-img">
                                <img src="assets/images/service/2.png" alt="Quicky's Service" />
                            </div>
                            <div class="service-content">
                                <h3 class="heading">Quality Products</h3>
                                <p class="short-desc">We ensure our product
                                    quality all times</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="service-item">
                            <div class="service-img">
                                <img src="assets/images/service/3.png" alt="Quicky's Service" />
                            </div>
                            <div class="service-content">
                                <h3 class="heading">3 Day Return</h3>
                                <p class="short-desc">Our producr return policy
                                    is very easy & simple</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServiceBanner;
