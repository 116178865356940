import React from 'react';

const Slider = () => {
    return (
        <div class="slider-area">

            <div class="quicky-element-carousel home-slider arrow-style" data-slick-options='{
                "slidesToShow": 1,
                "slidesToScroll": 1,
                "infinite": true,
                "arrows": true,
                "dots": false,
                "autoplay" : true,
                "fade" : true,
                "autoplaySpeed" : 7000,
                "pauseOnHover" : false,
                "pauseOnFocus" : false
                }' data-slick-responsive='[
                {"breakpoint":768, "settings": {
                "slidesToShow": 1
                }},
                {"breakpoint":575, "settings": {
                "slidesToShow": 1
                }}
            ]'>
                <div class="slide-item animation-style-01 bg-1">
                    <div class="slider-progress"></div>
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="inner-slide">
                                    <div class="slide-content">
                                        <h2>DBS Supply</h2>
                                        <p class="short-desc">Produce and supply various Household materials all over
                                            the world which were very attractive</p>
                                        <div class="slide-btn">
                                            <a class="quicky-btn horizontal-line_ltr" href="shop-left-sidebar.html">Discover Now!</a>
                                        </div>
                                    </div>
                                    <div class="slider-img">
                                        <img src="assets/images/slider/slider-product/8.png" alt="Slider Product" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Slider;
