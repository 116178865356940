import React from 'react'
import Slider from "react-slick"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from '../components/Footer';

const Fauxwood = () => {
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    var settings1 = {
        slidesToShow: 3,
        asNavFor: ".sp-img_slider",
        focusOnSelect: true,
        arrows: true,
        spaceBetween: 30,
        responsive: [
            { breakpoint: 1501, settings: { slidesToShow: 3 } },
            { breakpoint: 1200, settings: { slidesToShow: 2 } },
            { breakpoint: 992, settings: { slidesToShow: 4 } },
            { breakpoint: 768, settings: { slidesToShow: 3 } },
            { breakpoint: 575, settings: { slidesToShow: 2 } }
        ]
    };

    return (
        <>
            <div class="sp-area sp-affiliate_area pt-30">
                <div class="container">
                    <div class="sp-nav">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="sp-img_area">
                                    <div class="sp-img_slider slick-img-slider quicky-element-carousel">
                                        <Slider {...settings}>
                                            <div class="single-slide red zoom">
                                                <img src="https://dbsblinds.ca/images/faux04.jpg" alt="DBS Supplies Furniture" />
                                            </div>
                                            <div class="single-slide orange zoom">
                                                <img src="https://dbsblinds.ca/images/faux06.jpg" alt="DBS Supplies Furniture" />
                                            </div>
                                        </Slider>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="sp-content ml-lg-4">
                                    <div class="sp-heading">
                                        <h5><a href="#">2" Fauxwood Blind</a></h5>
                                    </div>
                                    <p>Dress up any window with these Better Homes and Gardens 2 inch Faux Wood Blinds. They are made of moisture-resistant and heat-resistant PVC and do not fade, crack, rust or warp, making them suitable for all weather conditions
                                    </p>
                                    <div class="rating-box">
                                        <ul>
                                            <li><i class="zmdi zmdi-star"></i></li>
                                            <li><i class="zmdi zmdi-star"></i></li>
                                            <li><i class="zmdi zmdi-star"></i></li>
                                            <li class="silver-color"><i class="zmdi zmdi-star"></i></li>
                                            <li class="silver-color"><i class="zmdi zmdi-star"></i></li>
                                        </ul>
                                    </div>
                                    <div class="sp-essential_stuff">
                                        <ul>
                                            <li>Brands <a href="javascript:void(0)">DBS Supllies</a></li>
                                            <li>Product Code: <a href="javascript:void(0)">dbs-supplies 1we6</a></li>
                                            <li>Availability: <a href="javascript:void(0)">In Stock</a></li>
                                        </ul>
                                    </div>
                                    <div class="qty-btn_area">
                                        <a href="/Contact" class="qty-buy_btn">Buy Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="product-tab_area pt-90">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="sp-product-tab_nav">
                                <div class="product-tab">
                                    <ul class="nav product-menu">
                                        <li><a class="active" data-bs-toggle="tab" href="#description"><span>Description</span></a>
                                        </li>
                                        <li><a data-bs-toggle="tab" href="#specification"><span>Specification</span></a></li>
                                    </ul>
                                </div>
                                <div class="tab-content uren-tab_content">
                                    <div id="description" class="tab-pane active show" role="tabpanel">
                                        <div class="product-description">
                                            <ul>
                                                <li>
                                                    <span class="title">Description</span>
                                                    <span>Dress up any window with these higher Homes and Gardens 2 inch Fauxwood Wood Blinds. they’re fabricated from moisture-resistant and heat-resistant PVC and don’t fade, crack, rust warp, creating them appropriate for all climatic conditions.These Better Homes and Gardens blinds have a realistic wood grain that is imprinted directly onto the slat. They are easily adjustable and look wonderful when mounted inside your window frame. The white faux wood blinds blend flawlessly with painted trim for a professional and complete look. They are compatible with a variety of window treatments and look charming with the included matching valance alone. These blinds come with easy-to-follow instructions for stress-free installation and include all the necessary hardware for completion, plus a matching valance and a tilt wand. They are an excellent choice for light control, as well as for privacy, in your home or office.</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Fauxwood