import React from 'react'

const BreadcrumbSmall = ({ b }) => {
    return (
        <div class="container">
            <div class="breadcrumb-content">
                <ul>
                    <li><a href="index.html">Home</a> &gt; Product</li>
                </ul>
            </div>
        </div>
    )
}

export default BreadcrumbSmall