import React from 'react';

const ContactForm = () => {
    return (
        <div class="contact-main-page ptb-40">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="contact-form-content">
                            <h3 class="contact-page-title">Tell Us Your Message</h3>
                            <div class="contact-form">
                                <form id="contact-form" action="https://whizthemes.com/mail-php/mamunur/quicky/quicky.php">
                                    <div class="form-group">
                                        <label>Your Name <span class="required">*</span></label>
                                        <input type="text" name="con_name" required />
                                    </div>
                                    <div class="form-group">
                                        <label>Your Email <span class="required">*</span></label>
                                        <input type="email" name="con_email" required />
                                    </div>
                                    <div class="form-group">
                                        <label>Subject</label>
                                        <input type="text" name="con_subject" />
                                    </div>
                                    <div class="form-group form-group-2">
                                        <label>Your Message</label>
                                        <textarea name="con_message"></textarea>
                                    </div>
                                    <div class="form-group mb-0">
                                        <button type="submit" value="submit" id="submit" class="contact-form_btn" name="submit">send</button>
                                    </div>
                                    <p class="form-messege mt-3 mb-0"></p>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ContactForm;
