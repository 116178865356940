import React from 'react'

const Breadcrumb = ({ b }) => {
    return (
        <div class="breadcrumb-area">
            <div class="container">
                <div class="breadcrumb-content">
                    <h2>{b}</h2>
                    <ul>
                        <li><a href="index.html">Home</a></li>
                        <li class="active">{b}</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Breadcrumb