import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <div class="footer-area">
            <div class="footer-top_area bg-buttery-white">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="footer-widgets_area">
                                <div class="logo-area">
                                    <a href="javascript:void(0)">
                                        <img src="/assets/images/menu/logo/1.png" alt="DBS Logo" />
                                    </a>
                                </div>
                                <p class="short-desc">Produce and supply various Household materials all over the world
                                    which were very attractive</p>
                                <div class="social-link">
                                    <ul>
                                        <li class="facebook">
                                            <a href="https://www.facebook.com" data-bs-toggle="tooltip" target="_blank" title="Facebook">
                                                <i class="icon-social-facebook"></i>
                                            </a>
                                        </li>
                                        <li class="twitter">
                                            <a href="https://twitter.com" data-bs-toggle="tooltip" target="_blank" title="Twitter">
                                                <i class="icon-social-twitter"></i>
                                            </a>
                                        </li>
                                        <li class="google-plus">
                                            <a href="javascript:void(0)" data-bs-toggle="tooltip" target="_blank" title="Google Plus">
                                                <i class="icon-social-skype"></i>
                                            </a>
                                        </li>
                                        <li class="instagram">
                                            <a href="https://www.instagram.com/" data-bs-toggle="tooltip" target="_blank" title="Instagram">
                                                <i class="icon-social-instagram"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-3">
                            <div class="footer-widgets_area">
                                <h3 class="heading">Quick Link</h3>
                                <div class="footer-widgets">
                                    <ul>
                                        <li><Link to='/'>Home</Link></li>
                                        <li><Link to='/Contact'>Contact</Link></li>
                                        <li><Link to='/About'>About us</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-3">
                            <div class="footer-widgets_area">
                                <h3 class="heading">Products</h3>
                                <div class="footer-widgets">
                                    <ul>
                                        <li><a href="/Shop/Fauxwood">Fauxwood</a></li>
                                        <li><a href="/Shop/Zebra">Zebra blinds</a></li>
                                        <li><a href="/Shop/Roller">Roller Shade</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="footer-widgets_area">
                                <h3 class="heading">Get in Touch</h3>
                                <div class="footer-widgets">
                                    <div class="widgets-mail">
                                    </div>
                                    <a class="widgets-contects" href="tel://+1 (604) 503-5340">(604) 503-5340</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer-bottom_area">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-md-6">
                            <div class="copyright">
                                <span>Copyright &copy;{new Date().getFullYear()}
                                    <a href="index.html">DBS Supply - </a>
                                    <a href="" target="_blank">All Rights Reserved.</a>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="payment">
                                <img src="/assets/images/menu/logo/1.png" alt="DBS Blinds" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Footer;
