import React from 'react'
import AboutSection from '../components/AboutSection'
import Breadcrumb from '../components/Breadcrumb'
import Footer from '../components/Footer'

const About = () => {
    return (
        <>
            <Breadcrumb b={'About'} />
            <AboutSection />
            <Footer />
        </>
    )
}

export default About