import React from 'react';

const Banner = () => {
    return (
        <div class="banner-area pt-100">
            <div class="container">
                <div class="row">
                    <div class="col-md-4 col-6 custom-xxs-col">
                        <div class="banner-item">
                            <div class="banner-img">
                                <a href="javascript:void(0)">
                                    <img src="assets/images/banner/furniture.jpg" alt="Quicky's Banner" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-8 col-6 custom-xxs-col">
                        <div class="row banner-wrap">
                            <div class="col-md-6">
                                <div class="banner-item">
                                    <div class="banner-img">
                                        <a href="javascript:void(0)">
                                            <img src="assets/images/banner/carpet.jpg" alt="Quicky's Banner" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="banner-item">
                                    <div class="banner-img">
                                        <a href="javascript:void(0)">
                                            <img src="assets/images/banner/cabinets.jpg" alt="Quicky's Banner" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row banner-wrap d-none d-md-block">
                            <div class="col-lg-12">
                                <div class="banner-item">
                                    <div class="banner-img">
                                        <a href="javascript:void(0)">
                                            <img src="assets/images/banner/flooring.jpg" alt="Quicky's Banner" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 d-block d-md-none">
                        <div class="banner-item specific-banner_item">
                            <div class="banner-img">
                                <a href="javascript:void(0)">
                                    <img src="assets/images/banner/flooring.jpg" alt="Quicky's Banner" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Banner;
