import React from 'react'

const AboutSection = () => {
    return (
        <>
            <div class="main-content_area">
                {/* <!-- Begin Banner With Text Area --> */}
                <div class="banner-with_text overflow-hidden pt-55 pb-30">
                    <div class="container-fluid p-0">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="banner-area pt-md-30">
                                    <img class="w-100" src="/assets/images/about-us/1.jpg" alt="DBS Supplies about page image" />
                                </div>
                            </div>
                            <div class="col-lg-6 pt-25">
                                <div class="text-area">
                                    <h1 class="pb-30">About - DBS Supplies </h1>
                                    <p class="short-desc pearl-text_color">DBS Supplies is branch of Diversity Business Solution Inc establish in 2017 in BC building up a sales network in North America.
                                    </p>
                                    <p class="short-desc pearl-text_color pb-0">DBS Supplies specialized in Window Blinds, Laminate Flooring, Carpets, Kitchen cabinet and Furniture.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Banner With Text Area End Here --> */}
                {/* <!-- Begin Banner With Text Area --> */}
                <div class="banner-with_text banner-with_text-2 overflow-hidden pt-55">
                    <div class="container-fluid p-0">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="text-area pt-md-55">
                                    <span>DBS Supplies</span>
                                    <h2 class="heading-2">Our Aim </h2>
                                    <p class="short-desc-2 pearl-text_color">OUR aim is to provide a whole spectrum of high-quality products and materials to our customers. We ensure a fast and reliable product delivery</p>
                                    <h2 class="heading-2">Our Service</h2>
                                    <p class="short-desc-2 pearl-text_color">DBS Supplies specialized in Window Blinds, Laminate Flooring, Carpets, Kitchen cabinet  and Furniture.</p>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="banner-area">
                                    <img class="w-100" src="assets/images/about-us/DBS-SUPPLY.png" alt="Quicky's Banner" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Banner With Text Area End Here -->
                <!-- Begin Banner With Text Area Three --> */}
                <div class="banner-with_text banner-with_text-3 overflow-hidden pt-90 pb-30">
                    <div class="container-fluid p-0">
                        <div class="banner-nav bg-black">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="text-area">
                                        <span class="white-text_color">DBS Supplies</span>
                                        <h2 class="heading white-text_color">DBS - Making your choice! 😊</h2>
                                        <p class="short-desc pearl-text_color">In the short span of time, we have concluded good and long business rotation with first class construction and consulting companies which largely enable us to expand our business scopes. By staying up-to-date with the current trend, and by working with the finest and most passionate sales and marketing team, we are sure to stay ahead of the competition.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                {/* <!-- Team Area End Here -->
                <!-- Begin Newsletter With Testimonial Area --> */}
                <div class="newsletter-with_testimonial pt-30 pb-55">
                    <div class="container-fluid">
                        <div class="row align-items-center">
                            <div class="col-lg-12">
                                <div class="newsletter-area bg-wisp-pink">
                                    <div class="newsletter-wrap">
                                        <span>Subscribe our</span>
                                        <h2 class="heading">Newsletter Now</h2>
                                        <div class="newsletter-form_wrap">
                                            <form action="https://devitems.us11.list-manage.com/subscribe/post?u=6bbb9b6f5827bd842d9640c82&amp;id=05d85f18ef" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="newsletters-form validate" target="_blank" novalidate>
                                                <div id="mc_embed_signup_scroll">
                                                    <div id="mc-form" class="mc-form subscribe-form">
                                                        <input id="mc-email" class="newsletter-input" type="email" autocomplete="off" placeholder="Enter email address" />
                                                        <button class="newsletter-btn" id="mc-submit">Send</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <p class="short-desc">Get the latest update of our new products,
                                            offers and promotions</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutSection