import React from 'react';
import Breadcrumb from '../components/Breadcrumb';
import ContactForm from '../components/ContactForm';
import Footer from '../components/Footer';

const Contact = () => {
    return (
        <>
            <Breadcrumb b={'Contact'} />
            <ContactForm />
            <Footer />
        </>
    )
};

export default Contact;
